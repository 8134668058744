const columns = ["校友会名称","Organisation Name","学校名称", 
  {
    name: "网站地址",
    options: {
      customBodyRender: (value, tableMeta, updateValue) => {
        return (
          <a href={"http://"+value} target="_blank">{value}</a>
        );
      }
    }
  }, 
  "联系电话", "其它联系方式(如微信等等)"];

const options = {
  filterType: "checkbox",
  download: false,
  print: false,
  // resizableColumns: true
};
const data = [ [ '上海工程技术大学加拿大校友会', '', '上海工程技术大学', 'saac.xiaoju.ca', '', '' ],
[ '', '', '东北大学', '', '', '' ],
[ '', '', '东北师范大学', '', '', '' ],
[ '', '', '东北财经大学', '', '', '' ],
[ '', '', '东华大学', '', '', '' ],
[ '', '', '东南大学', '', '', '' ],
[ '', '', '中北大学', '', '', '' ],
[ '', '', '中南财经政法大学', '', '', '' ],
[ '', '', '中国人民大学', '', '', '' ],
[ '', '', '中国传媒大学', '', '', '' ],
[ '', '', '中国医科大学', '', '', '' ],
[ '', '', '中国地质大学', '', '', '' ],
[ '', '', '中国科技大学', '', '', '' ],
[ '', '', '中央美院', '', '', '' ],
[ '', '', '中山大学', '', '', '' ],
[ '', '', '兰州大学', '', '', '' ],
[ '', '', '北京外国语大学', '', '', '' ],
[ '北京林业大学加拿大校友会', '', '北京林业大学', 'bfuaac.com', '', '' ],
[ '', '', '北京理工大学', '', '', '' ],
[ '', '', '北京科技大学', '', '', '' ],
[ '', '', '北京联合大学', '', '', '' ],
[ '', '', '北京航空航天大学', '', '', '' ],
[ '', '', '北京邮电大学', '', '', '' ],
[ '', '', '华东交通大学', '', '', '' ],
[ '', '', '华东理工大学', '', '', '' ],
[ '', '', '华侨大学', '', '', '' ],
[ '', '', '华南农业大学', '', '', '' ],
[ '', '', '华南理工大学', '', '', '' ],
[ '', '', '南京医科大学', '', '', '' ],
[ '南京大学大多伦多地区校友会',
  'NANJING UNIVERSITY ALUMNI ASSOCIATION OF GREAT TORONTO AREA',
  '南京大学',
  'njuniversity.ca',
  '',
  'info@njuniversity.ca' ],
[ '', '', '南京工业大学', '', '', '' ],
[ '南京师范大学加拿大校友会',
  '',
  '南京师范大学',
  'nnuca.xiaoju.ca',
  '416-799-1818',
  '' ],
[ '', '', '南京理工大学', '', '', '' ],
[ '', '', '南京航空航天大学', '', '', '' ],
[ '', '', '南京邮电大学', '', '', '' ],
[ '', '', '南开大学', '', '', '' ],
[ '', '', '厦门大学', '', '', '' ],
[ '', '', '合肥工业大学', '', '', '' ],
[ '', '', '哈尔滨医科大学', '', '', '' ],
[ '', '', '哈尔滨工业大学', '', '', '' ],
[ '', '', '哈尔滨工程大学', '', '', '' ],
[ '', '', '四川大学', '', '', '' ],
[ '', '', '国防科技大学', '', '', '' ],
[ '', '', '复旦大学', '', '', '' ],
[ '', '', '大连海事大学', '', '', '' ],
[ '', '', '大连理工大学', '', '', '' ],
[ '', '', '天津中医药大学', '', '', '' ],
[ '', '', '天津医科大学', '', '', '' ],
[ '', '', '天津大学', '', '', '' ],
[ '', '', '天津工业大学', '', '', '' ],
[ '', '', '天津师范大学', '', '', '' ],
[ '', '', '天津理工大学', '', '', '' ],
[ '', '', '天津财经大学', '', '', '' ],
[ '', '', '太原理工大学', '', '', '' ],
[ '', '', '山东大学', '', '', '' ],
[ '', '', '山东师范大学', '', '', '' ],
[ '', '', '广东外国语大学', '', '', '' ],
[ '', '', '广东工业大学', '', '', '' ],
[ '', '', '成都理工大学', '', '', '' ],
[ '暨南大学多伦多校友会', '', '暨南大学', 'jnutaa.xiaoju.ca', '', '' ],
[ '', '', '杭州电子科技大学', '', '', '' ],
[ '', '', '桂林电子科技大学', '', '', '' ],
[ '', '', '武汉大学', '', '', '' ],
[ '', '', '江南大学', '', '', '' ],
[ '', '', '沈阳农业大学', '', '', '' ],
[ '', '', '沈阳工业大学', '', '', '' ],
[ '', '', '沈阳建筑大学', '', '', '' ],
[ '', '', '沈阳航空航天大学', '', '', '' ],
[ '', '', '沈阳药科大学', '', '', '' ],
[ '', '', '浙江大学', '', '', '' ],
[ '', '', '深圳大学', '', '', '' ],
[ '', '', '清华大学', '', '', '' ],
[ '', '', '湖南大学', '', '', '' ],
[ '', '', '电子科技大学', '', '', '' ],
[ '', '', '福州大学', '', '', '' ],
[ '', '', '福建农林大学', '', '', '' ],
[ '', '', '苏州大学', '', '', '' ],
[ '苏州科技大学加拿大校友会', '', '苏州科技大学', 'sust.xiaoju.ca', '', '' ],
[ '', '', '西北工业大学', '', '', '' ],
[ '', '', '西南交通大学', '', '', '' ],
[ '', '', '西南大学', '', '', '' ],
[ '', '', '西南政法大学', '', '', '' ],
[ '', '', '西南科技大学', '', '', '' ],
[ '', '', '西南财经大学', '', '', '' ],
[ '', '', '西安交通大学', '', '', '' ],
[ '', '', '西安外国语大学', '', '', '' ],
[ '', '', '西安建筑科技大学', '', '', '' ],
[ '', '', '西安理工大学', '', '', '' ],
[ '', '', '西安电子科大', '', '', '' ],
[ '', '', '辽宁工业大学', '', '', '' ],
[ '', '', '辽宁师范大学', '', '', '' ],
[ '重庆大学加拿大校友会',
  'Chongqing University Alumni Association(Canada)',
  '重庆大学',
  'www.cquaa.ca',
  '647-637-6803',
  '微信：JohnJunLi' ],
[ '', '', '重庆工业大学', '', '', '' ],
[ '重庆师范大学加拿大校友会(筹)', '', '重庆师范大学', 'cqnuaa.xiaoju.ca', '', '' ],
[ '', '', '长安大学', '', '', '' ],
[ '', '', '长春理工大学', '', '', '' ],
[ '', '', '陕西师范大学', '', '', '' ],
[ '', '', '集美大学', '', '', '' ],
[ '青岛大学加拿大校友会', '', '青岛大学', '', '', '' ],
[ '青岛大学多伦多校友会', '', '青岛大学', '', '', '' ] ];


export {columns, data, options}
