import IndexLayout from '../layouts';
import Wrapper from '../components/Wrapper';
import SiteNav from '../components/header/SiteNav';
import { SiteHeader, outer, inner, SiteMain } from '../styles/shared';
import * as React from 'react';
import { Link } from 'gatsby';
import { css } from '@emotion/core';
import MUIDataTable from "mui-datatables";
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core/styles';

import { PostFullHeader, PostFullTitle, NoImage, PostFull } from '../templates/post';
import { PostFullContent } from '../components/PostContent';
import Footer from '../components/Footer';
import Helmet from 'react-helmet';
import {data, columns, options} from '../data/organisations'
import { render } from 'react-dom';

const PageTemplate = css`
  .site-main {
    background: #fff;
    padding-bottom: 4vw;
  }
`;

// const About: React.FunctionComponent = () => (
class About extends React.Component {
  getMuiTheme = () => createMuiTheme({
    overrides: {
      MUIDataTableBodyCell: {
        root: {
          // backgroundColor: "#FF0000",
          'font-size':'1.5rem'
        }
      },
      MUIDataTableHeadCell: {
        root: {
          // backgroundColor: "#FF0000",
          'font-size':'1.5rem'
        }
      },
      'MUITablePagination': {
        root: {
          // backgroundColor: "#FF0000",
          'font-size':'1.5rem'
        }
      }
    }
  })

  render() {
    return (      
  <IndexLayout>
    <Helmet>
      <title>Organisations</title>
    </Helmet>
    <Wrapper css={PageTemplate}>
      <header css={[outer, SiteHeader]}>
        <div css={inner}>
          <SiteNav />
        </div>
      </header>
      <main id="site-main" className="site-main" css={[SiteMain, outer]}>

        <article className="post page" css={[PostFull, NoImage]}>
          <PostFullHeader>
            <PostFullTitle>校友会名单</PostFullTitle>
            <h3>以下是现任会长群校友会名单（学校中文名称的google sheet顺序）</h3>
            <Link to="/#contact">请联系我们进行信息更新</Link>
          </PostFullHeader>
          <MuiThemeProvider theme={this.getMuiTheme()}>
            <MUIDataTable
              title={"名单可排序，筛选等等"}
              data={data}
              columns={columns}
              options={options}
            />
          </MuiThemeProvider>
          <PostFullContent className="post-full-content">
            <div className="post-content">

            </div>
          </PostFullContent>
        </article>
      </main>
      <Footer />
    </Wrapper>
  </IndexLayout>
    )
  }
}
// );

export default About;
